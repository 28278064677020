import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FilterEmptyPipe } from '@shared/pipes/filter-empty/filter-empty.pipe';
import * as fromSidebar from '@core/store/actions/sidebar.action';
import { IUser } from '@app/auth/models/user';
import * as fromAuth from '@app/auth/store';
import { PriceRequest } from '@app/shared/models/product.model';
import { ApiService } from '@app/core/api.service';
import { EventBus, EmitEvent, Events } from '@app/core/service/event-bus.service';

@Component({
  selector: 'app-sidebar-notifications',
  templateUrl: './sidebar-notifications.component.html',
  styleUrls: ['./sidebar-notifications.component.scss']
})
export class SidebarNotificationsComponent implements OnInit, OnDestroy {
  @Input() height: number;
  @Input() priceRequests: PriceRequest[];

  user$: Observable<IUser>;
  userSub: Subscription;
  constructor(
    private store: Store<fromRoot.AppState>,
    private filterEmpty: FilterEmptyPipe,
    private router: Router,
    private apiService: ApiService,
    private eventBus: EventBus,
  ) {
    this.user$ = this.store.pipe(select(fromAuth.getUser));
  }

  ngOnInit() {
    this.userSub = this.user$.subscribe((user) => {
      if (user && user.profile && user.profile.is_user_interface_active) {
      }
    });

  }

  navigateTo(params: any) {
    this.router.navigate([
      '/search/',
      params.skn,
    ]);
    this.store.dispatch(new fromSidebar.CloseSidebar());
  }
  deleteNotification(priceRequest: PriceRequest) {
    this.apiService.post('/products/price-requests/notif_deleted_notification/' + priceRequest.id + '/').subscribe(() => {
      // reload
      this.eventBus.emit(new EmitEvent(Events.reloadUnreadNotifications, 1));
    });
  }
  getColor(priceRequest: PriceRequest) {
    switch (priceRequest.status) {
      case 10:
        return 'green';
      case 11:
        return 'red';
      default:
        return 'orange';
    }
  }
  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }
}

/**
 * @module AppModule
 */ /** */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromAuth from '@app/auth/store';
import * as layout from '@core/store/actions/layout.action';
import { Observable, Subscription , of, from, concat, throwError } from 'rxjs';
import * as fromCart from '@pages/cart/store';
import * as LanguageActions from '@core/store/actions/language.action';
import * as TranslationActions from '@core/store/actions/translation.action';
import { PageScrollConfig, PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { DOCUMENT } from '@angular/common';
import { IUser, IResultsModel } from '@app/auth/models/user';
import * as fromSearchHistory from '@core/store/actions/search-history.action';
import * as fromLastRoute from '@core/store/actions/last-route.action';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { HelperService } from '@shared/services/helper.service';
import { LanguageService } from '@core/service/language.service';
import { fadeIn, fadeInDown } from '@shared/animations';
import { VersionService } from '@shared/services/version.service';
import { SentryService } from '@shared/services/sentry.service';
import { TranslationService } from '@core/service/translation.service';
import { PageTitleService } from '@core/service/pageTitle.service';
import { filter, concatMap, delay, finalize, catchError, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { ApiService } from '../api.service';
import * as toast from '@app/core/store/actions/toast.action';
import { PriceRequest, PriceRequestStatus, ProductItem } from '@app/shared/models/product.model';
import { EventBus, Events } from '../service/event-bus.service';
import * as fromProduct from '@app/core/store/actions/product.action';
import { SkubaCompanyCodeEnum } from '@app/shared/models/company.model';

const TABSTOPMSG: 'STOP' = 'STOP';

/**
 * @whatItDoes Returns the {@link AppComponent} view.
 * @consumers {@link AppModule}
 */
@Component({
  selector: 'app-skuba-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  animations: [fadeIn, fadeInDown]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('class.app-skuba-root') hostClass = false;

  /** Scrollable container */
  @ViewChild('container', { static: false }) private container: ElementRef;

  showMobileMenu$: Observable<boolean>;
  showSearchIcon$: Observable<boolean>;
  loggedIn$: Observable<boolean>;
  cart$: Observable<boolean>;
  loggedIn: boolean;
  router$: Observable<any>;
  routerSub: Subscription;
  routerEventsSub: Subscription;
  user$: Observable<IUser>;
  user: IUser;
  userSub: Subscription;
  showCookiesPolicy = true;
  pages: boolean;

  isLocalhost = false;
  isUnsupportedBrowser = false;

  routeSub: Subscription;
  isLogin = false;
  layoutStyle = {
    'background-image': '',
    'background-size': '',
    'background-repeat': ''
  };
  fontSize = 20;
  showSuggestApp = false;

  screenSizeMediaListener: MediaQueryList;
  screenSizeMediaCallbackFix: (ev: { readonly matches: boolean; readonly media: string }) => any;
  showCartMiniAndLanguage = false;
  maxPower = 2; // Maximum power of 2 (2^maxPower)
  startPower = 1;
  currentPower = this.startPower; // Start from 2^1
  lastTimeout: NodeJS.Timeout = null;
  tabHidden = false;
  priceRequests: PriceRequest[] = [];
  enablePriceChatCheck = false;
  enabledPriceChatCheck = false;
  eventBusSub: Subscription;
  product$: Observable<ProductItem>;
  productSub: Subscription;
  lastLoadedProduct: ProductItem;
  eventBusUnreadSub: Subscription;
  eventBusResetCurPowSub: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private element: ElementRef,
    private store: Store<fromRoot.AppState>,
    private rootStore: Store<fromRoot.AppState>,
    private pageScrollService: PageScrollService,
    private localStorage: LocalStorageService,
    private router: Router,
    private helperService: HelperService,
    private languageService: LanguageService,
    private versionService: VersionService,
    private sentryService: SentryService,
    private cd: ChangeDetectorRef,
    private pageTitleService: PageTitleService,
    private translationService: TranslationService,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    private eventBus: EventBus,
  ) {
    this.showMobileMenu$ = this.store.pipe(select(fromRoot.getShowHamburgerMenu));
    this.loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
    this.showSearchIcon$ = this.store.pipe(select(fromRoot.getShowSearchIcon));
    this.cart$ = this.store.pipe(select(fromRoot.getCartLoaded));
    this.router$ = this.store.pipe(select(fromRoot.getRouterState));
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.product$ = this.rootStore.pipe(select(fromRoot.getProduct));

    PageScrollConfig.defaultDuration = 0;
    PageScrollConfig.defaultInterruptible = false;
  }

  ngOnInit() {
    this.eventBusSub = this.eventBus.on(Events.refreshPriceChatMessages, () => {
      console.log('Event bus refreshPriceChatMessages');
      this.restartPriceChatMessageCheck();
    });
    this.eventBusUnreadSub = this.eventBus.on(Events.reloadUnreadNotifications, () => {
      console.log('Event bus reloadUnreadNotifications');
      this.realoadUnreadNotifications();
    });
    this.eventBusResetCurPowSub = this.eventBus.on(Events.resetCurrentPower, () => {
      console.log('Event bus resetCurrentPower');
      this.resetCurrentPower();
    });
    const cookieEnabled = this.checkCookiesEnabled();
    if (!cookieEnabled) {
      this.routerSub = this.router$.subscribe(route => {
        if (route && route.state && route.state.url) {
          if (route.state.url.substring(0, 17) !== '/cookies-disabled') {
            this.router.navigate(['/cookies-disabled']);
          }
        } else {
          this.router.navigate(['/cookies-disabled']);
        }
      });
    } else {
      this.clearDjangoCookies();
      this.showMessageAboutIE();
      this.sentryService.init();
      if (window.location.hostname) {
        const host = window.location.hostname.toLowerCase();
        if (
          host.startsWith('localhost') ||
          host.startsWith('http://localhost') ||
          host.startsWith('127.0.0.1') ||
          host.startsWith('http://127.0.0.1')
        ) {
          this.isLocalhost = true;
        } else {
          this.isLocalhost = false;
          this.versionService.init();
          this.maxPower = 7;
        }
      }
      this.hostClass = true;
      const currentLanguage = this.localStorage.getItem('language') || 'en';
      this.applyCurrentLanguage(currentLanguage);
      // if (currentLanguage) {
      //   this.applyCurrentLanguage(currentLanguage);
      // } else {
      //   currentLanguage = 'lt';
      //   this.applyCurrentLanguage(currentLanguage);
      //   this.routerEventsSub = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe( (navEnd:NavigationEnd) => {
      //     const pathName = navEnd.urlAfterRedirects;
      //     if (pathName.startsWith('/register') || pathName.startsWith('/login')) {
      //       this.languageService.getLanguage().subscribe(
      //         language => {
      //           if (language !== currentLanguage) {
      //             this.applyCurrentLanguage(language, true);
      //           }
      //         },
      //         error => {
      //           this.routerEventsSub.unsubscribe();
      //         }
      //       );
      //     }
      //   });
      // }
      this.helperService.updateCookiesPolicy();

      this.helperService.cookiesPolicy$.subscribe(status => {
        this.showCookiesPolicy = status;
      });

      this.screenSizeMediaListener = window.matchMedia('(max-width: 1350px)');
      this.screenSizeMediaCallbackFix = this.screenSize1350MediaCallback(this);
      this.screenSizeMediaListener.addListener(this.screenSizeMediaCallbackFix);
      this.screenSizeMediaCallbackFix(this.screenSizeMediaListener);
      this.productSub = this.product$.subscribe(product => {
        // Save product for later cached products updating
        this.lastLoadedProduct = product;
      });
    }
    setTimeout(() => {
      this.loggedIn$.subscribe(isLoggedIn => {
        setTimeout(() => {
          this.loggedIn = !!isLoggedIn;
        });

        if (isLoggedIn) {
          if (this.userSub) {
            this.userSub.unsubscribe();
          }
          this.userSub = this.user$.subscribe(user => {
            this.user = user;
            if (user && user.profile && user.profile.force_reset_password) {
              if (this.router.url === '/profile/user/change-pass') {
                // do nothing as we allow to change user password
              } else {
                this.router.navigate(['/profile/user/change-pass']);
              }
            } else if (user && user.profile && user.profile.status !== 1) {
              if (
                this.router.url === '/profile/user' ||
                this.router.url === '/profile/user/info') {
                // do nothing as we allow to change user info
              } else {
                this.router.navigate(['/profile/user/info']);
              }
            } else if (user && user.profile) {
              this.loadCartStore();
              // TODO: uncomment when we start ems-mobile
              this.showSuggestApp = this.checkIfMobileVersionSupported(user) && this.isMobile() &&
              (
                user.id === 84 ||
                user.id === 121 ||
                user.id === 140
              );
              this.enablePriceChatCheck = (
                user.id === 84 ||
                user.id === 121 ||
                user.id === 140 ||
                user.id === 150706 ||
                user.profile.company.skuba_company_id === SkubaCompanyCodeEnum.Finland ||
                user.profile.company.skuba_company_id === SkubaCompanyCodeEnum.VilniusSkuba
              );
              if (this.enablePriceChatCheck && !this.enabledPriceChatCheck) {
                this.enabledPriceChatCheck = true;
                this.initPriceChat();
              }
              this.store.dispatch(new fromSearchHistory.SearchHistoryLoad());
            }
          });
        } else {
          if (this.userSub) {
            this.userSub.unsubscribe();
          }
        }
        this.cd.detectChanges();
      });
    });
  }
  initPriceChat() {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        if (this.lastTimeout) {
          clearTimeout(this.lastTimeout);
          this.lastTimeout = null;
        }
        this.tabHidden = true;
        console.log('User is moving away.');
      } else {
        console.log('User focused app');
        this.tabHidden = false;
        this.currentPower = this.startPower;
        this.startPriceChatMessageCheck();
      }
    }, false);

    this.startPriceChatMessageCheck();
    this.realoadUnreadNotifications();
  }
  realoadUnreadNotifications() {
    this.apiService.get('/products/price-requests/').subscribe((priceRequests: IResultsModel<PriceRequest>) => {
      this.priceRequests = priceRequests.results.map(pr => {
        if (pr.messages.length > 0) {
          pr.notifMessage = pr.messages[pr.messages.length - 1].message;
        } else {
          pr.notifMessage = pr.message;
        }
        return pr;
      });
    });
  }
  readNotifications() {
    this.apiService.post('/products/price-requests/read_notification/all/').subscribe((priceRequests: PriceRequest[]) => {
      this.priceRequests = priceRequests.map(pr => {
        if (pr.messages.length > 0) {
          pr.notifMessage = pr.messages[pr.messages.length - 1].message;
        } else {
          pr.notifMessage = pr.message;
        }
        return pr;
      });
    });
  }
  isMobile() {
    if (typeof screen.orientation !== 'undefined') {
      return true;
    }
    return false;
  };

  applyCurrentLanguage(currentLanguage, unsubscribeRouterEvents: boolean = false) {
    this.store.dispatch(new LanguageActions.SetActiveLanguage(currentLanguage));
    this.store.dispatch(new LanguageActions.LanguagesLoad());
    this.store.dispatch(new TranslationActions.SetTranslationLanguage(currentLanguage));
    if (this.routerEventsSub && unsubscribeRouterEvents) {
      this.routerEventsSub.unsubscribe();
    }
  }

  checkCookiesEnabled() {
    let cookieEnabled = navigator.cookieEnabled ? true : false;
    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1 ? true : false;
    }
    return cookieEnabled;
  }

  showMessageAboutIE() {
    if (this.isIE()) {
      this.isUnsupportedBrowser = true;
    }
  }

  clearDjangoCookies() {
    // Clear django cookies. We use rest api and sometimes cookies are set and do not allow to post results.
    document.cookie = 'sessionid=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  checkIfMobileVersionSupported(user: IUser) {
    if (
      user && user.profile &&
      !user.profile.company.enable_payment_tatrac &&
      user.user_settings.enable_basket_logic) {
      return true;
    }
    return false;
  }
  openApp() {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 30);
    const token = this.localStorage.getItem('id_token');
    const domain = (this.isLocalhost ? '' : 'domain=skuba.eu;');
    document.cookie = 'skuba-id_token=' + token + ';' + domain + ' expires=' + date + ';sameSite=Strict; Secure;';
    window.location.href = this.isLocalhost ? 'https://localhost:8080/' : 'https://emsm.skuba.eu/';
  }

  continue() {
    this.showSuggestApp = false;
  }

  setFontSize() {
    const size = ((window.innerWidth + (window.innerHeight * 2)) / 3);
    this.fontSize = Math.round(size * 0.04);
  }

  onResize(event) {
    this.setFontSize();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setFontSize();
    }, 0);
    this.routeSub = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(routeEvent => {
      const routeSerializer = new fromRoot.CustomSerializer();
      const routeState = routeSerializer.serialize(this.router.routerState.snapshot);
      this.pageTitleService.setTitle({ state: routeState });
    });
    this.routerSub = this.router$.subscribe(route => {
      if (route && route.state && route.state.url) {
        this.toggleBackgroundImage(route);
        if (
          route.state.url.substring(0, 6) !== '/login' &&
          route.state.url.substring(0, 9) !== '/register' &&
          route.state.url.substring(0, 5) !== '/auth' &&
          route.state.url.substring(0, 6) !== '/email' &&
          route.state.url.substring(0, 7) !== '/logout'
        ) {
          this.pages = true;
          this.store.dispatch(new fromLastRoute.LastRouteLoad(route.state.url));
        } else {
          this.pages = false;
        }
      }
      this.scroll();
    });
  }

  toggleBackgroundImage(route) {
    if (
      route.state.url.substring(0, 6) === '/login' ||
      route.state.url.substring(0, 9) === '/register' ||
      route.state.url.substring(0, 5) === '/auth' ||
      route.state.url.substring(0, 6) === '/email' ||
      route.state.url.substring(0, 7) === '/logout'
    ) {
      this.layoutStyle = {
        'background-image': 'url(\'/assets/images/skuba_bg.png\')',
        // 'background-size': 'contain',
        // 'background-repeat': 'round'
        'background-size': '',
        'background-repeat': ''
      };
    } else {
      this.layoutStyle = {
        'background-image': '',
        'background-size': '',
        'background-repeat': ''
      };
    }
  }

  toggleHamburgerMenu($event: boolean) {
    if ($event) {
      this.store.dispatch(new layout.CloseHamburger());
    } else {
      this.store.dispatch(new layout.OpenHamburger());
    }
  }

  loadCartStore() {
    this.cart$.subscribe(loaded => {
      if (!loaded && this.loggedIn) {
        this.userSub = this.user$.subscribe(user => {
          if (user && user.profile && user.profile.is_user_interface_active) {
            this.disableUppercaseStyle(user);
            this.setErrorDebugInfo(user);
            this.store.dispatch(new fromCart.LoadCart(true));
          }
        });
      }
    });
  }
  setErrorDebugInfo(user: IUser) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.username,
      fid_name: user.profile ? user.profile.company.name : 'Not loaded',
      fid: user.profile ? user.profile.company.skuba_company_id : 'Not loaded',
    });
  }
  disableUppercaseStyle(user: IUser) {
    const uppercaseStyle = document.getElementById('sk-no-uppercase-style-setting');
    if (uppercaseStyle) {
      uppercaseStyle.remove();
    }
    if (user.hide_uppercase_style) {
      const style = document.createElement('style');
      style.setAttribute('id', 'sk-no-uppercase-style-setting');
      style.type = 'text/css';
      style.innerHTML = '* { text-transform: none !important; }';
      document.getElementsByTagName('head')[0].appendChild(style);
    } else {

    }
  }

  scroll(): void {
    const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance({
      document: this.document,
      scrollTarget: '.app-skuba-root',
      scrollingViews: this.container ? [this.container.nativeElement] : null
    });
    this.pageScrollService.start(pageScrollInstance);
  }
  isIE() {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11

    return (msie > 0 || trident > 0);
  }

  isActive() {
  }

  hideModal() {
    this.localStorage.setItem('cookiesPolicy', false);
    this.helperService.updateCookiesPolicy();
  }

  ngOnDestroy() {
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    this.screenSizeMediaListener.removeListener(this.screenSizeMediaCallbackFix);
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
      this.lastTimeout = null;
    }
    if (this.eventBusSub) {
      this.eventBusSub.unsubscribe();
    }
    if (this.productSub) {
      this.productSub.unsubscribe();
    }
    if (this.eventBusUnreadSub) {
      this.eventBusUnreadSub.unsubscribe();
    }
    if (this.eventBusResetCurPowSub) {
      this.eventBusResetCurPowSub.unsubscribe();
    }
  }
  screenSize1350MediaCallback(that: AppComponent): (ev: { readonly matches: boolean; readonly media: string }) => any {
    return function(ev: { readonly matches: boolean; readonly media: string }) {
      if (ev.matches) {
        // console.log('Screen is small.');
        that.showCartMiniAndLanguage = true;
      } else {
        // console.log('Screen is big.');
        that.showCartMiniAndLanguage = false;
      }
      that.cdRef.detectChanges();
    };
  }
  checkPriceChatMessages(callback: Function) {
    if (this.loggedIn) {
      console.log(`Function runs after ${Math.pow(2, this.currentPower)} seconds.`);

      this.apiService.get('/products/price-requests-notifications/').subscribe((priceRequests: IResultsModel<PriceRequest>) => {
        // update left menu list
        if (priceRequests.count === 0) {
          callback();
          return;
        }
        if (priceRequests.results.filter(pr => !pr.read).length > 0) {
          this.realoadUnreadNotifications();
        }
        this.resetCurrentPower();
        if (this.lastLoadedProduct && this.lastLoadedProduct.main && this.lastLoadedProduct.main.skn) {
          if (priceRequests.results.filter(pr => pr.product.skn === this.lastLoadedProduct.main.skn).length > 0) {
            this.rootStore.dispatch(new fromProduct.ProductLoad(this.lastLoadedProduct.main.skn));
            // FIXME: add filter by brand and only realod related rows
          }
        }
        const notifications = priceRequests.results.map(pr => {
          if (pr.messages.length > 0) {
            pr.notifMessage = pr.messages[pr.messages.length - 1].message;
          } else {
            pr.notifMessage = pr.message;
          }
          return pr;
        });
        const postNotification = (notification: PriceRequest) => new Promise<void>((resolve, reject) => {
          if (this.tabHidden) {
            reject('STOP. Tab is hidden.');
            return;
          }
          let displayedMessage = '';
          const message = notification.notifMessage;
          if (notification.status === PriceRequestStatus.accepted) {
            displayedMessage = this.translationService.translate('PC_REQ_APPR') + '\n\nSKUBA: ' + message;
            this.store.dispatch(new toast.ShowSuccessPlainToast(
              displayedMessage
              + '\n\n'
              + notification.product.name
              + ' / '
              + notification.brand
              + '\n\n'
              + 'SKN: ' + notification.product.skn, true, '/search/' + notification.product.skn));
          } else if (notification.status === PriceRequestStatus.rejected) {
            displayedMessage = this.translationService.translate('PC_REQ_NOT_APPR') + '\n\nSKUBA: ' + message;
            this.store.dispatch(new toast.ShowErrorToast(
              displayedMessage
              + '\n\n'
              + notification.product.name
              + '\n\n'
              + 'SKN: ' + notification.product.skn, false, true, '/search/' + notification.product.skn));
          } else {
            displayedMessage = 'SKUBA: ' + message;
            this.store.dispatch(new toast.ShowWarningPlainToast(
              displayedMessage
              + '\n\n'
              + notification.product.name
              + ' / '
              + notification.brand
              + '\n\n'
              + 'SKN: ' + notification.product.skn, false, true, '/search/' + notification.product.skn));
          }

          this.apiService.post('/products/price-requests/update_notification_state/' + notification.id + '/').subscribe(() => {
            resolve();
          }, (err) => {
            reject(err);
          });
        });
        concat(
          of(...notifications).pipe(
            concatMap(notification =>
              from(postNotification(notification)).pipe(
                concatMap(() => of(notification).pipe(delay(5000))),
                finalize(() => {
                }),
              ),
            ),
            // this throw error will enshure that sub error method gets call back, but not complete
            catchError(err => throwError('PC_ERR_NOTIF_CHECK', err)),
          ).pipe(tap(() => {
          }))
        )
          .subscribe(notification => {
            console.log('Price notification loop OK: ', notification);
          }, (err) => {
            if ((String(err)).startsWith(TABSTOPMSG)) {
              // Normal stop
            } else {
              console.error('Price notification loop ERROR catch err in sub:', err);
            }
          }, () => {
            console.log('Price notification loop Completed. Reschedule check.');
            callback();
          });
      }, (error) => {
        // Error getting price requests, just skip it, maybe event skip log to sentry?
        // this.store.dispatch(new toast.ShowErrorToast('Klaida. \n' + error));
        console.error(error);
      });
    }
  }
  restartPriceChatMessageCheck() {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
      this.lastTimeout = null;
    }
    this.currentPower = this.startPower;
    this.startPriceChatMessageCheck();
  }
  startPriceChatMessageCheck() {
    if (this.tabHidden) {
      return;
    }
    if (this.currentPower < this.maxPower) {
      this.currentPower++;
    } else {
      console.log('Reached max currentPower.', this.currentPower);
    }
    const timeoutDuration = Math.pow(2, this.currentPower) * 1000;
    this.lastTimeout = setTimeout(() => {
      this.checkPriceChatMessages(() => {
        this.startPriceChatMessageCheck();
      });
    }, timeoutDuration);
  }
  resetCurrentPower() {
    this.currentPower = this.startPower;
  }

}

import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
// union instead of enum is encouraging
export var Events;
(function (Events) {
    Events[Events["refreshPriceChatMessages"] = 0] = "refreshPriceChatMessages";
    Events[Events["reloadUnreadNotifications"] = 1] = "reloadUnreadNotifications";
    Events[Events["resetCurrentPower"] = 2] = "resetCurrentPower";
})(Events || (Events = {}));
export class EmitEvent {
    constructor(name, value) {
        this.name = name;
        this.value = value;
    }
}
export class EventBus {
    constructor() {
        this.subject = new Subject();
    }
    emit(event) {
        this.subject.next(event);
    }
    on(event, action) {
        return this.subject
            .pipe(filter((e) => e.name === event), map((e) => e.value))
            .subscribe(action);
    }
}
EventBus.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventBus_Factory() { return new EventBus(); }, token: EventBus, providedIn: "root" });

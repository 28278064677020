/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar-notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../pipes/translate/translate.pipe";
import * as i4 from "../../../../../core/service/translation.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../../../../../node_modules/ng-zorro-antd/popconfirm/ng-zorro-antd-popconfirm.ngfactory";
import * as i7 from "ng-zorro-antd/tooltip";
import * as i8 from "ng-zorro-antd/popconfirm";
import * as i9 from "./sidebar-notifications.component";
import * as i10 from "../../../../pipes/filter-empty/filter-empty.pipe";
import * as i11 from "@angular/router";
import * as i12 from "../../../../../core/api.service";
import * as i13 from "../../../../../core/service/event-bus.service";
var styles_SidebarNotificationsComponent = [i0.styles];
var RenderType_SidebarNotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarNotificationsComponent, data: {} });
export { RenderType_SidebarNotificationsComponent as RenderType_SidebarNotificationsComponent };
function View_SidebarNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "sh-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "strong", [["class", "search-query"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTo({ "skn": _v.context.$implicit.product.skn }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "i", [["class", "fas fa-handshake"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "color": 0 }), (_l()(), i1.ɵted(6, null, [" ", ":"])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslationService, i5.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ": ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslationService, i5.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 7, "nz-popconfirm", [["nzTitle", "'DELETE_CONFIRM' | translate"]], null, [[null, "nzOnConfirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nzOnConfirm" === en)) {
        var pd_0 = (_co.deleteNotification(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_NzPopconfirmComponent_0, i6.RenderType_NzPopconfirmComponent)), i1.ɵprd(6144, null, i7.NzTooltipBaseComponentLegacy, null, [i8.NzPopconfirmComponent]), i1.ɵdid(20, 573440, null, 1, i8.NzPopconfirmComponent, [i1.ChangeDetectorRef, [8, null]], { nzTitle: [0, "nzTitle"] }, { nzOnConfirm: "nzOnConfirm" }), i1.ɵqud(335544320, 1, { nzTitleTemplate: 0 }), (_l()(), i1.ɵeld(22, 16777216, null, 0, 3, "a", [["nz-popconfirm", ""]], [[2, "ant-popover-open", null]], null, null, null, null)), i1.ɵdid(23, 4931584, null, 0, i8.NzPopconfirmDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Renderer2, [2, i8.NzPopconfirmComponent], [8, null]], { directiveNameTitle: [0, "directiveNameTitle"] }, null), (_l()(), i1.ɵted(24, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslationService, i5.Store, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 5, 0, _co.getColor(_v.context.$implicit)); _ck(_v, 4, 0, currVal_1); var currVal_7 = "'DELETE_CONFIRM' | translate"; _ck(_v, 20, 0, currVal_7); var currVal_9 = ""; _ck(_v, 23, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.brand; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("PC_MENU_LABEL")); _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.product.name; _ck(_v, 10, 0, currVal_3); var currVal_4 = _v.context.$implicit.brand; _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("SKN")); var currVal_6 = _v.context.$implicit.product.skn; _ck(_v, 16, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 23).isTooltipComponentVisible; _ck(_v, 22, 0, currVal_8); var currVal_10 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform("DELETE")); _ck(_v, 24, 0, currVal_10); }); }
export function View_SidebarNotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslationService, i5.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "search-history-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "height.px": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "search-history"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarNotificationsComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 6, 0, (_co.height - 244)); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.priceRequests; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("NOTIFICATIONS")); _ck(_v, 1, 0, currVal_0); }); }
export function View_SidebarNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar-notifications", [], null, null, null, View_SidebarNotificationsComponent_0, RenderType_SidebarNotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i9.SidebarNotificationsComponent, [i5.Store, i10.FilterEmptyPipe, i11.Router, i12.ApiService, i13.EventBus], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarNotificationsComponentNgFactory = i1.ɵccf("app-sidebar-notifications", i9.SidebarNotificationsComponent, View_SidebarNotificationsComponent_Host_0, { height: "height", priceRequests: "priceRequests" }, {}, []);
export { SidebarNotificationsComponentNgFactory as SidebarNotificationsComponentNgFactory };

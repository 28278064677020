<div *ngIf="showSuggestApp" class="transparent-layer">
  <div class="card suggest-app">
    <div class="card-body suggest-app-body">
      <div class="message" [ngStyle]="{'font-size': fontSize + 'px'}" (window:resize)="onResize($event)">
        {{'MESSAGE_TO_APP' | translate}}
      </div>
      <div class="buttons">
        <app-button className="btn-red" [buttonStyle]="{'font-size': fontSize + 'px'}" text="OPEN" (click)="openApp()"></app-button>
        <app-button className="btn" [buttonStyle]="{'font-size': fontSize + 'px'}" text="CONTINUE_BROWSING" (click)="continue()"></app-button>
      </div>
    </div>
  </div>
</div>
<nz-alert
  class="unsupported-browser-banner"
  *ngIf="isUnsupportedBrowser"
  nzBanner
  [nzMessage]="'BROWSER_NOT_SUPPORTED'| translate"
  nzCloseable
></nz-alert>
<app-skuba-layout [ngStyle]="layoutStyle">
  <div class="language-switch-container" *ngIf="!(loggedIn$ | async)"><app-language-switch></app-language-switch></div>
  <app-skuba-toolbar
    (openMenu)="toggleHamburgerMenu($event)"
    *ngIf="(loggedIn$ | async)"
    [openMobileMenu]="showMobileMenu$ | async"
    [showSearchIcon]="showSearchIcon$ | async"
  >
  </app-skuba-toolbar>
  <app-unauth-header *ngIf="pages && !(loggedIn$ | async)"></app-unauth-header>
  <main id="main" [ngClass]="{ unauth: pages && !(loggedIn$ | async) }">
    <div id="scroll-top"></div>
    <app-sidebar *ngIf="(loggedIn$ | async)" [priceRequests]="priceRequests" (markRead)="readNotifications()"></app-sidebar>
    <div class="floating-lang-switch-container" *ngIf="showCartMiniAndLanguage && (loggedIn$ | async)">
      <app-language-switch class="floating-lang-switch"></app-language-switch>
    </div>
    <div class="floating-cart-mini-container" *ngIf="showCartMiniAndLanguage && (loggedIn$ | async)">
      <app-cart-mini-container class="floating-cart-mini"></app-cart-mini-container>
    </div>
    <router-outlet></router-outlet>
    <app-tawkto *ngIf="!isLocalhost && (loggedIn$ | async)"></app-tawkto>
  </main>
  <app-footer *ngIf="(loggedIn$ | async)"></app-footer>
  <app-spinner></app-spinner>
  <app-version-error></app-version-error>
  <app-cookies-policy-modal
    *ngIf="showCookiesPolicy && (loggedIn$ | async)"
    (updateStatus)="hideModal($event)"
    [@fadeInDown]
  >
  </app-cookies-policy-modal>
</app-skuba-layout>

import { Component, OnInit, ChangeDetectorRef, ViewRef, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { IToast, ToastType } from '@app/core/models/toast';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
  toasts: IToast[] = [];
  toastsTimeouts: Map<IToast, NodeJS.Timeout> = new Map();
  toastSub: Subscription;
  constructor(
    private store: Store<fromRoot.AppState>,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.toastSub) {
      this.toastSub.unsubscribe();
    }
    this.toastSub = this.store.pipe(select(fromRoot.getToast)).subscribe((toast: IToast) => {
      if (!toast) {
        // clear toasts when an empty toast is received
        this.toasts = [];
        return;
      }

      // add toast to array
      this.toasts.push(toast);

      if (environment.production) {
        if (
          toast.type === ToastType.Error
        ) {
          if (!toast.skipReport) {
            // TODO: we need to save toast errors to our DB with more detailed description
            // Now we try to not flood sentry with them.
            // Raven.captureMessage(`${toast.type} ${toast.message}`);
          }
        }
      }


     if (!(<ViewRef> this.cd).destroyed) {
        this.cd.detectChanges();
      } else {
        // console.log('#1 TRY TO USE toast cdRef detectChanges CHANGES ');
      }
      if (!toast.persistent) {
        const toastTimeout = setTimeout(() => {
          this.toastsTimeouts.delete(toast);
          this.removeToast(toast);
          // https://stackoverflow.com/questions/37849453/attempt-to-use-a-destroyed-view-detectchanges
          if (!(<ViewRef> this.cd).destroyed) {
            this.cd.detectChanges();
          } else {
            // console.log('#2 TRY TO USE destroyed toast cdRef detectChanges CHANGES ');
          }
        }, 4000);
        this.toastsTimeouts.set(toast, toastTimeout);
      }
    });
  }

  mouseEnter(toast: IToast) {
    if (!toast.persistent) {
      clearTimeout(this.toastsTimeouts.get(toast));
    }
  }

  mouseLeave(toast: IToast) {
    if (!toast.persistent) {
      const toastTimeout = setTimeout(() => {
        this.toastsTimeouts.delete(toast);
        this.removeToast(toast);
        if (!(<ViewRef> this.cd).destroyed) {
          this.cd.detectChanges();
        } else {
         // console.log(' #3 TRY TO USE  toast component cdRef detectChanges CHANGES ');
        }
      }, 2000);
      this.toastsTimeouts.set(toast, toastTimeout);
    }
  }

  removeToast(toast: IToast) {
    this.toasts = this.toasts.filter(x => x !== toast);
  }

  cssClass(toast: IToast) {
    if (!toast) {
      return;
    }

    // return css class based on toast type
    switch (toast.type) {
      case ToastType.SuccessPlain:
        return 'toast toast-success-plain';
      case ToastType.Success:
        return 'toast toast-success';
      case ToastType.Error:
        return 'toast toast-error';
      case ToastType.Info:
        return 'toast toast-info';
      case ToastType.Warning:
        return 'toast toast-warning';
      case ToastType.WarningPlain:
        return 'toast toast-warning-plain';
    }
  }

  ngOnDestroy(): void {
    if (this.toastSub) {
      this.toastSub.unsubscribe();
    }
  }

}

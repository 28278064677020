import { Component } from '@angular/core';

import { IFilterAngularComp } from '@ag-grid-community/angular-legacy';
import { IFilterParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-choice-custom-filter',
  template: `
    <div>
      <div style="padding: 5px; border-bottom: 1px solid grey">
        <b>{{'FILTER' | translate}}</b>
      </div>
      <div *ngFor="let proficiency of filterValues" style="margin-top: 3px">
        <label style="padding-left: 4px">
          <input type="radio" [checked]="selected === proficiency" (change)="onButtonPressed(proficiency)"/>
          {{proficiency.name}}
          <ng-container *ngIf="proficiency.icon">
          <i
            [class]="proficiency.icon"
            [ngStyle]="{'color': (proficiency.iconColor)}"></i>
          </ng-container>
        </label>
      </div>
    </div>
  `,
  styles: [''],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ChoiceCustomFilter implements IFilterAngularComp {
  public filterValues = [
    {
      name: '',
      v: null,
      icon: <string> null,
      iconColor: <string> null,
    },
  ];
  public filterValidValues = {};

  public selected: any = this.filterValues[0];

  public params: IFilterParams;

  constructor() {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    if (params.colDef.filterParams && params.colDef.filterParams.filterValues) {
      this.filterValues = this.filterValues.concat(params.colDef.filterParams.filterValues);
      params.colDef.filterParams.filterValues.filter(v => v.v !== 0).forEach(v => {
        this.filterValidValues[v.v] = true;
      });
      // this.filterValidValues = params.colDef.filterParams.filterValues.filter(v => v.v !== 0);
    }
  }

  public onButtonPressed(proficiency: any) {
    this.selected = proficiency;
    this.params.filterChangedCallback();
  }

  public doesFilterPass(params) {
    if (this.selected.v === null) {
      return true;
    }
    const value = this.params.valueGetter(params);
    console.warn('--', value, this.selected.v, params, this.filterValidValues);
    if (value === this.selected.v) {
      return true;
    } else if (this.selected.v === 0 && !this.filterValidValues[value]) {
      return true;
    }

    return false;
  }

  public isFilterActive() {
    return this.selected !== this.filterValues[0];
  }

  getModel(): any {
    if (!this.isFilterActive()) {
      return null;
    }

    return { value: this.selected.v };
  }

  setModel(model: any): void {
    this.selected = model ? this.filterValues.filter(f => f.v === model.value) : this.filterValues[0];
  }
}

<div id="toast-container" class="toast-right">
  <div *ngFor="let toast of toasts"
       [ngClass]="cssClass(toast)"
       class="animated bounceInRight">
    <div (mouseenter)="mouseEnter(toast)" (mouseleave)="mouseLeave(toast)" [routerLink]="toast.url ? toast.url : undefined" [ngStyle]="{'cursor': toast.url ? 'pointer' : 'inherit'}">
      <ng-container *ngIf="toast.priceChat">
        <i class="fas fa-handshake" style="padding-left: 6px;"></i> {{'PC_MENU_LABEL' | translate}}:<br><br>
      </ng-container>
      
      <i class="close-toast fas fa-times" (click)="removeToast(toast)"></i>
      <div class="toast-message">{{toast.message | translate}}</div>
      
    </div>
  </div>
</div>

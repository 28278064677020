<div class="sidebar">
  <div class="sidebar-menu" [ngClass]="{'sidebar-open': sidebarOpen}">
    <i
      nz-tooltip
      [nzTooltipTitle]="'SEARCH_HISTORY' | translate"
      [nzMouseEnterDelay]="0.01"
      [nzMouseLeaveDelay]="0.01"
      nzTooltipPlacement="topRight"
      [(nzVisible)]="searchPopoverVisible"
      [nzTooltipTrigger]="!sidebarOpen ? 'hover' : 'null'"
      class="material-icons shadow-1"
      (click)="toggleSidebar('history')"
      [ngClass]="{'item-active': sidebarOpen}"
      *ngIf="!sidebarOpen || lastOpenedType === 'history'"
      >history</i>
    <span *ngIf="(!sidebarOpen || lastOpenedType === 'notif') && (priceRequests && priceRequests.length > 0)">
      <div *ngIf="!sidebarOpen && priceRequestsNonRead > 0" style="
          position: absolute;
          width: 20px;
          height: 20px;
          background-color: red;
          top: 59px;
          left: 40px;
          border: 1px solid #fd9800;
          border-radius: 50%;
          text-align-last: center;
        ">
        <span *ngIf="priceRequestsNonRead > 0" style="color: #ffffff;">{{priceRequestsNonRead}}</span>
      </div>
      <i
        nz-tooltip
        [nzTooltipTitle]="'NOTIFICATIONS' | translate"
        [nzMouseEnterDelay]="0.01"
        [nzMouseLeaveDelay]="0.01"
        nzTooltipPlacement="topRight"
        [(nzVisible)]="notificationsPopoverVisible"
        [nzTooltipTrigger]="!sidebarOpen ? 'hover' : 'null'"
        class="material-icons shadow-1"
        (click)="toggleSidebar('notif')"
        [ngClass]="{'item-active': sidebarOpen, 'notifications': true}"
        >notifications</i>
    </span>
  </div>

  <div class="sidebar-body shadow-2" [ngClass]="{'sidebar-open': sidebarOpen}">
    <i class="material-icons close-sidebar" (click)="closeSidebar()">close</i>
    <div class="sidebar-box" *ngIf="lastOpenedType === 'history'">
      <app-sidebar-search-history [history]="history" [height]="height" ></app-sidebar-search-history>
    </div>
    <div class="sidebar-box" *ngIf="lastOpenedType === 'notif' && (priceRequests && priceRequests.length > 0)">
      <app-sidebar-notifications [priceRequests]="priceRequests" [height]="height" ></app-sidebar-notifications>
    </div>
  </div>
</div>

import { IUser } from '@app/auth/models/user';
import { CartModel } from '@app/pages/cart/models/cart.model';

export class ProductItem {
  stock?: ProductStock[];
  pics_url?: ProductPics;
  main: ProductInfo;
  price_requests: PriceRequest[];
}

export class PriceRequest {
  id: number;
  created_at: string;
  updated_at: string;
  user: number;
  product: ProductInfo;
  shop: number | null;
  message: string;
  price: string;
  req_price: string;
  quantity: number;
  card: number;
  warehouse: number;
  messages: PriceRequestMessage[];
  brand: string;
  status: number;
  valid: boolean;
  used: boolean;
  notified: boolean;
  read: boolean;
  duedate: string;

  notifMessage!: string;
  approved_price!: string;
}
export enum PriceRequestStatus {
  error = -2,
  canceled = -1,
  unconfirmed = 0,
  sent = 1,
  uploaded = 2,
  accepted = 10,
  rejected = 11,
}
export class PriceRequestMessage {
  id: number;
  created_at: string;
  message: string;
  user: IUser;
  status: number;
  price: string;

  initial: boolean;
  final: boolean;
  priceRequest: PriceRequest;
}

export class ProductInfo {
  id: number;
  dimensions?: string | number;
  manufacturer?: string;
  manufacturer_name?: string;
  name: string;
  skn: string;
  sub_group_name?: string;
  buttons?: any;
  orig_3d_picture_url?: string;
}

export class ProductPics {
  pics: any[];
  pics_3d: any[];
}

export class ProductStockSimple {
  brand: string;
  card: number;
  is_central: boolean;
  price: number;
  price_eur: number;
  price_without_vat: number;
  price_without_vat_eur: number;
  list_price: number;
  list_price_eur: number;
  list_price_without_vat: number;
  list_price_without_vat_eur: number;
  price_wo_vat_eur: number;
  quantity: string | number;
  skn: string;
  warehouse: number;
  warehouse_title: string;
  deposit: number;
  deposit_eur: number;
  deposit_without_vat: number;
  deposit_without_vat_eur: number;
  on_the_way: boolean;
}

export class ProductStock {
  card_number: number;
  list_price: number;
  warehouse_title: string;
  price_eur: number;
  price_wo_vat: number;
  price: number;
  list_price_wo_vat_eur: number;
  list_price_wo_vat: number;
  price_wo_vat_eur: number;
  brand: string;
  code: string;
  brand_description: string;
  description: string;
  quantity: string | number;
  allow_reservation: boolean;
  warehouse: number;
  list_price_eur: number;
  add_note = false;
  note: string;
  is_central: boolean;
  is_special_price: boolean;
  shop: any;
  on_the_way: boolean;
  on_the_way_local: boolean;
  on_the_way_date: string;
  is_carried: boolean;
  deposit: number;
  deposit_eur: number;
  deposit_wo_vat: number;
  deposit_wo_vat_eur: number;
  increment: number;

  // local state variables
  warningPopoverVisible = false;
  showListPrice = true;
  showPrice = true;
  historyStock = false;
  centralStock = false;
  nearStock = false;
  localStock = false;
  isDeposit = false;
  isOnTheWay = false;
  formattedDeposit = '';
  depositLabelKey = '';
  minQuantity = 1;
  maxQuantity = 1;
  warehouse_title_lines: string[] = [];
  priceRequests: PriceRequest[] = [];
  priceRequestsMessages: PriceRequestMessage[];
  canMakePriceRequest: boolean;
  lastPriceRequest: PriceRequest;
  priceRequestColor: string;
}

export class CartItemUpdate {
  id: number;
  product_id: number;
  card_number: number;
  warehouse_code: number;
  quantity: number;
  quantity_added?: number;
  stock_quantity?: number;
  note: string;
  ndo: string;
  client_ref_id: number;
  is_central: boolean;
  is_carried: boolean;
  on_the_way: boolean;
  expired?: boolean;
  additional_info?: string;
  skn?: string;
  deposit?: number;
  brand?: string;
  oldQuantity?: number;
}

export class CartItemUpdateRequest {
  update: CartItemUpdate;
  skn: string;
  product: any;
  matchId: string;
}
export class AddProductUpdateRequest {
  update: CartItemUpdate;
  acknowledged: boolean;
  oldQuantity: number;
}
export class UpdateResult {
  cart: CartModel;
  error: boolean;
  data: any;
}
export class CartItemUpdateResult extends UpdateResult {
  update: CartItemUpdateRequest;
}

export class AddProductUpdateResult extends UpdateResult {
  update: AddProductUpdateRequest;
}
export class ISimilarProductsList {
  count: number;
  results: ISimilarProduct;
}

export class ISimilarProduct {
  id: number;
  skn: number;
  manufacturer_name: string;
  manufacturer: number;
  sub_group_name: string;
  sub_group: number;
  name: string;
  dimensions: string;
  picture_url: string;
  search_info: any;
  search_source: any;
  search_score: number;
  search_value: any;
}

export interface IProductDescriptionList {
  count: number;
  results: IProductDescription[];
}

export interface IProductDescription {
  skn: string;
  product: number;
  company: number;
  brand: string;
  content_type: string;
  content: string;
}

export class ProductStateChecks {
  isStockState(extraProduct: ProductStock, key: string, user: IUser) {
    if (
      key === 'black' && extraProduct.quantity <= 0) {
      return true;
    }
    if (
      key === 'yellow' && extraProduct.is_central) {
      return true;
    }
    if (
      key === 'blue' &&
      (extraProduct.quantity > 0 || (typeof extraProduct.quantity === 'string' && extraProduct.quantity.substring(1, 3) === '10')) &&
      !extraProduct.is_central &&
      !(extraProduct.shop && user && extraProduct.shop.id === user.profile.shop.id)) {
      return true;
    }
    if (
      key === 'green' &&
      (extraProduct.quantity > 0 || (typeof extraProduct.quantity === 'string' && extraProduct.quantity.substring(1, 3) === '10')) &&
      !extraProduct.is_central &&
      (extraProduct.shop && user && extraProduct.shop.id === user.profile.shop.id)) {
      return true;
    }

    return false;
  }
  isDeposit(extraProduct: ProductStock) {
    return extraProduct.deposit > 0;
  }
  isOnTheWay(extraProduct: ProductStock) {
    return extraProduct.on_the_way;
  }
}
export class PriceRequestForm {
  id: number;
  req_id: number;
  brand: string;
  is_central: boolean;
  req_price: number;
  price: number;
  product_id: number;
  warehouse: number;
  card: number;
  quantity: number;
  message: string;
  client_ref_id: number;
  code: string;
  file_id: number;
}

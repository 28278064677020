/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipes/translate/translate.pipe";
import * as i3 from "../../../core/service/translation.service";
import * as i4 from "@ngrx/store";
import * as i5 from "./choice-custom.component.filter";
var styles_ChoiceCustomFilter = [""];
var RenderType_ChoiceCustomFilter = i0.ɵcrt({ encapsulation: 0, styles: styles_ChoiceCustomFilter, data: {} });
export { RenderType_ChoiceCustomFilter as RenderType_ChoiceCustomFilter };
function View_ChoiceCustomFilter_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "i", [], [[8, "className", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(4, { "color": 0 })], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, _v.parent.context.$implicit.iconColor); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }); }
function View_ChoiceCustomFilter_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["style", "margin-top: 3px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "label", [["style", "padding-left: 4px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "input", [["type", "radio"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onButtonPressed(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChoiceCustomFilter_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.icon; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selected === _v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
export function View_ChoiceCustomFilter_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["style", "padding: 5px; border-bottom: 1px solid grey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChoiceCustomFilter_1)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.filterValues; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("FILTER")); _ck(_v, 3, 0, currVal_0); }); }
export function View_ChoiceCustomFilter_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-choice-custom-filter", [], null, null, null, View_ChoiceCustomFilter_0, RenderType_ChoiceCustomFilter)), i0.ɵdid(1, 49152, null, 0, i5.ChoiceCustomFilter, [], null, null)], null, null); }
var ChoiceCustomFilterNgFactory = i0.ɵccf("app-choice-custom-filter", i5.ChoiceCustomFilter, View_ChoiceCustomFilter_Host_0, {}, {}, []);
export { ChoiceCustomFilterNgFactory as ChoiceCustomFilterNgFactory };

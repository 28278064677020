import { Component, HostListener, OnDestroy, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';

import * as fromRoot from '@app/reducers';
import * as fromSidebar from '@core/store/actions/sidebar.action';
import * as fromSearchHistory from '@core/store/actions/search-history.action';
import { takeUntil, filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { ISearchHistoryResult, ISearchHistoryItem } from '@app/shared/models/search-history.model';
import { PriceRequest } from '@app/shared/models/product.model';

type OpenedMenuType = 'history' | 'notif';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() priceRequests: PriceRequest[];
  @Output() readonly markRead = new EventEmitter<boolean>();
  priceRequestsNonRead = 0;
  sidebarOpen = false;
  sidebarOpen$: Observable<boolean>;
  searchPopoverVisible = false;
  notificationsPopoverVisible = false;
  lastOpenedType: OpenedMenuType = 'notif';
  height: number;

  routeSub: Subscription;

  searchHistory$: Observable<ISearchHistoryResult[]>;
  searchHistorySub: Subscription;
  history: ISearchHistoryItem[] = [];
  historyLoadedAtleaseOnce = false;

  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private localStorageService: LocalStorageService,
  ) {

    this.routeSub = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(routeEvent => {
      this.moveRight(this.sidebarOpen);
    });
    this.sidebarOpen$ = this.store.pipe(select(fromRoot.getShowSidebar));

    this.searchHistory$ = this.store.pipe(select(fromRoot.getSearchHistory));
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.height = this.getScreenHeight();
    if (this.localStorageService.hasKey('sidebar') && !this.localStorageService.getItem('sidebar')) {
      return;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.sidebarOpen && this.lastOpenedType === 'notif' && this.priceRequests.length === 0) {
      this.closeSidebar();
    }
    this.priceRequestsNonRead = this.priceRequests.filter(pr => !pr.read).length;
  }
  getScreenWidth() {
    if (
      document.documentElement &&
      (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
      return document.documentElement.clientWidth;
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
      return document.body.clientWidth;
    }
    return window.innerWidth;
  }

  getScreenHeight() {
    if (document.documentElement &&
      (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
      return document.documentElement.clientHeight;
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
      return document.body.clientHeight;
    }
    return window.innerHeight;
  }

  ngOnInit() {
    this.height = this.getScreenHeight();
    if (this.localStorageService.hasKey('sidebar')) {
      if (this.localStorageService.getItem('sidebar')) {
        this.openSidebar();
      } else {
        this.closeSidebar();
      }
    }
    this.sidebarOpen$.pipe(takeUntil(this.destroyed$)).subscribe(open => {
      this.sidebarOpen = open;
      if (this.sidebarOpen) {
        this.searchPopoverVisible = false;
        this.notificationsPopoverVisible = false;
      }
    });

    this.searchHistorySub = this.searchHistory$.subscribe(results => {
      this.history = [];
      let loaded = false;
      const tempSub = this.store.pipe(select(fromRoot.getSearchHistoryState)).subscribe(state => loaded = state.loaded);
      tempSub.unsubscribe();
      if (loaded) {
        this.historyLoadedAtleaseOnce = true;
      }

      results.map(item => {
        const title = item.text;
        const params = {
          number: item.number,
          search: item.text,
          skn_state: item.skn_state,
          card_number_state: item.card_number_state,
          codes_state: item.codes_state,
          context_state: item.context_state,
          tec_doc_state: item.tec_doc_state,
          sub_group: item.subgroup_id,
          sort: item.sort,
        };

        this.history.push({
          title: title,
          params: params
        });
      });
    });
  }

  toggleSidebar(type: OpenedMenuType): void {
    this.lastOpenedType = type;
    if (this.sidebarOpen) {
      this.closeSidebar();
    } else {
      this.openSidebar();
      if (type === 'notif') {
        if (this.priceRequests.filter(pr => !pr.read).length > 0) {
          this.markRead.emit(true);
        }
      }
    }
  }

  openSidebar(): void {
    this.store.dispatch(new fromSidebar.OpenSidebar());
    this.moveRight(true);
    this.localStorageService.setItem('sidebar', true);
    if (!this.historyLoadedAtleaseOnce && this.history.length <= 0) {
      this.store.dispatch(new fromSearchHistory.SearchHistoryLoad());
    }
  }

  closeSidebar(): void {
    this.store.dispatch(new fromSidebar.CloseSidebar());
    this.moveRight(false);
    this.localStorageService.setItem('sidebar', false);
  }

  moveRight(open) {
    const htmlCollection = document.getElementsByClassName(
      'inner-floating-container'
    ) as HTMLCollectionOf<HTMLElement>;
    if (this.getScreenWidth() < 1600 &&
      this.getScreenWidth() >= 1280
    ) {
      for (let i = 0; i < htmlCollection.length; i++) {
        if (open) {
          if (htmlCollection[i]) {
            htmlCollection[i].classList.add('move-right');
          }
        } else {
          if (htmlCollection[i]) {
            htmlCollection[i].classList.remove('move-right');
          }
        }
      }
    } else {
      for (let i = 0; i < htmlCollection.length; i++) {
        htmlCollection[i].classList.remove('move-right');
      }
    }
  }

  ngOnDestroy() {
    if (this.searchHistorySub) { this.searchHistorySub.unsubscribe(); }
    this.destroyed$.next();
    this.destroyed$.complete();
    this.routeSub.unsubscribe();
  }
}

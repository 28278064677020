/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng-zorro-antd/tooltip";
import * as i4 from "../../../pipes/translate/translate.pipe";
import * as i5 from "../../../../core/service/translation.service";
import * as i6 from "@ngrx/store";
import * as i7 from "../components/sidebar-search-history/sidebar-search-history.component.ngfactory";
import * as i8 from "../components/sidebar-search-history/sidebar-search-history.component";
import * as i9 from "../../../pipes/filter-empty/filter-empty.pipe";
import * as i10 from "@angular/router";
import * as i11 from "../components/sidebar-notifications/sidebar-notifications.component.ngfactory";
import * as i12 from "../components/sidebar-notifications/sidebar-notifications.component";
import * as i13 from "../../../../core/api.service";
import * as i14 from "../../../../core/service/event-bus.service";
import * as i15 from "./sidebar.component";
import * as i16 from "../../../../core/local-storage/local-storage.service";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "i", [["class", "material-icons shadow-1"], ["nz-tooltip", ""], ["nzTooltipPlacement", "topRight"]], [[2, "ant-tooltip-open", null]], [[null, "nzVisibleChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nzVisibleChange" === en)) {
        var pd_0 = ((_co.searchPopoverVisible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleSidebar("history") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "item-active": 0 }), i1.ɵdid(4, 4931584, null, 0, i3.NzTooltipDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Renderer2, [2, i3.NzTooltipBaseComponentLegacy], [8, null]], { nzMouseEnterDelay: [0, "nzMouseEnterDelay"], nzMouseLeaveDelay: [1, "nzMouseLeaveDelay"], nzVisible: [2, "nzVisible"], specificTitle: [3, "specificTitle"], directiveNameTitle: [4, "directiveNameTitle"], specificTrigger: [5, "specificTrigger"], specificPlacement: [6, "specificPlacement"] }, { nzVisibleChange: "nzVisibleChange" }), i1.ɵpid(131072, i4.TranslatePipe, [i5.TranslationService, i6.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["history"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "material-icons shadow-1"; var currVal_2 = _ck(_v, 3, 0, _co.sidebarOpen); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = 0.01; var currVal_4 = 0.01; var currVal_5 = _co.searchPopoverVisible; var currVal_6 = i1.ɵunv(_v, 4, 3, i1.ɵnov(_v, 5).transform("SEARCH_HISTORY")); var currVal_7 = ""; var currVal_8 = (!_co.sidebarOpen ? "hover" : "null"); var currVal_9 = "topRight"; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).isTooltipComponentVisible; _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "color: #ffffff;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.priceRequestsNonRead; _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "\n          position: absolute;\n          width: 20px;\n          height: 20px;\n          background-color: red;\n          top: 59px;\n          left: 40px;\n          border: 1px solid #fd9800;\n          border-radius: 50%;\n          text-align-last: center;\n        "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.priceRequestsNonRead > 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 6, "i", [["class", "material-icons shadow-1"], ["nz-tooltip", ""], ["nzTooltipPlacement", "topRight"]], [[2, "ant-tooltip-open", null]], [[null, "nzVisibleChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nzVisibleChange" === en)) {
        var pd_0 = ((_co.notificationsPopoverVisible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleSidebar("notif") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "item-active": 0, "notifications": 1 }), i1.ɵdid(7, 4931584, null, 0, i3.NzTooltipDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Renderer2, [2, i3.NzTooltipBaseComponentLegacy], [8, null]], { nzMouseEnterDelay: [0, "nzMouseEnterDelay"], nzMouseLeaveDelay: [1, "nzMouseLeaveDelay"], nzVisible: [2, "nzVisible"], specificTitle: [3, "specificTitle"], directiveNameTitle: [4, "directiveNameTitle"], specificTrigger: [5, "specificTrigger"], specificPlacement: [6, "specificPlacement"] }, { nzVisibleChange: "nzVisibleChange" }), i1.ɵpid(131072, i4.TranslatePipe, [i5.TranslationService, i6.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["notifications"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.sidebarOpen && (_co.priceRequestsNonRead > 0)); _ck(_v, 2, 0, currVal_0); var currVal_2 = "material-icons shadow-1"; var currVal_3 = _ck(_v, 6, 0, _co.sidebarOpen, true); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = 0.01; var currVal_5 = 0.01; var currVal_6 = _co.notificationsPopoverVisible; var currVal_7 = i1.ɵunv(_v, 7, 3, i1.ɵnov(_v, 8).transform("NOTIFICATIONS")); var currVal_8 = ""; var currVal_9 = (!_co.sidebarOpen ? "hover" : "null"); var currVal_10 = "topRight"; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 7).isTooltipComponentVisible; _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-sidebar-search-history", [], null, null, null, i7.View_SidebarSearchHistoryComponent_0, i7.RenderType_SidebarSearchHistoryComponent)), i1.ɵdid(2, 245760, null, 0, i8.SidebarSearchHistoryComponent, [i6.Store, i9.FilterEmptyPipe, i10.Router], { height: [0, "height"], history: [1, "history"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.height; var currVal_1 = _co.history; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SidebarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-sidebar-notifications", [], null, null, null, i11.View_SidebarNotificationsComponent_0, i11.RenderType_SidebarNotificationsComponent)), i1.ɵdid(2, 245760, null, 0, i12.SidebarNotificationsComponent, [i6.Store, i9.FilterEmptyPipe, i10.Router, i13.ApiService, i14.EventBus], { height: [0, "height"], priceRequests: [1, "priceRequests"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.height; var currVal_1 = _co.priceRequests; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "sidebar-menu"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "sidebar-open": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "sidebar-body shadow-2"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { "sidebar-open": 0 }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "i", [["class", "material-icons close-sidebar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_5)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_6)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar-menu"; var currVal_1 = _ck(_v, 4, 0, _co.sidebarOpen); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (!_co.sidebarOpen || (_co.lastOpenedType === "history")); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((!_co.sidebarOpen || (_co.lastOpenedType === "notif")) && (_co.priceRequests && (_co.priceRequests.length > 0))); _ck(_v, 8, 0, currVal_3); var currVal_4 = "sidebar-body shadow-2"; var currVal_5 = _ck(_v, 12, 0, _co.sidebarOpen); _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_6 = (_co.lastOpenedType === "history"); _ck(_v, 16, 0, currVal_6); var currVal_7 = ((_co.lastOpenedType === "notif") && (_co.priceRequests && (_co.priceRequests.length > 0))); _ck(_v, 18, 0, currVal_7); }, null); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 770048, null, 0, i15.SidebarComponent, [i10.Router, i6.Store, i16.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("app-sidebar", i15.SidebarComponent, View_SidebarComponent_Host_0, { priceRequests: "priceRequests" }, { markRead: "markRead" }, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };

export interface IToast {
  type: ToastType;
  message: string;
  skipReport?: boolean;
  persistent?: boolean;
  priceChat?: boolean;
  url?: string;
}

export enum ToastType {
  Success,
  Error,
  Info,
  Warning,
  SuccessPlain,
  WarningPlain
}

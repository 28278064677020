<h3>{{'NOTIFICATIONS' | translate}}</h3>
<div class="search-history-container" [ngStyle]="{'height.px': height - 244}">
  <div class="search-history">
    <div
      class="sh-item"
      *ngFor="let item of priceRequests">
      <strong
        (click)="navigateTo({'skn': item.product.skn})"
        class="search-query"
        [title]="item.brand"
      >
        <i class="fas fa-handshake"
          [ngStyle]="{'color': getColor(item)}"
        ></i> {{'PC_MENU_LABEL' | translate}}:<br><br>
        {{item.product.name}} <br><br> {{item.brand}}<br><br>{{'SKN' | translate}}: {{item.product.skn}}
        
        <!-- Patvirtinta:<br>Filtras kuro stamb.val. dXi12/ - DONALDSON<br>SKN: 2019062 -->
      </strong>
      <nz-popconfirm nzTitle="'DELETE_CONFIRM' | translate" (nzOnConfirm)="deleteNotification(item)">
        <a nz-popconfirm>{{'DELETE' | translate}}</a>
      </nz-popconfirm>
    </div>
  </div>
</div>
